import Swiper from 'swiper/bundle';

// import swiper styles bundle
import 'swiper/css/bundle';

// Thumbnail swiper 

let collectionItemThumbnailGalleries = document.getElementsByClassName('collection-item-thumbnail-swiper');

// Just get the first instance incase multiple elements exist
let collectionItemThumbnailGallery = collectionItemThumbnailGalleries[0];

let collectionCrossListing = document.getElementsByClassName('cross-listing__carousel');

// Main Swiper
let collectionItemGalleries = document.getElementsByClassName('collection-item-gallery-swiper');

let collectionItemThumbnailGallerySwiper = null;
let collectionItemGallerySwiper = null;
let collectionCrossListingSwiper = null;

let crossListingSwiper = null;

// Just get the first instance incase multiple elements exist
let collectionItemGallery = collectionItemGalleries[0];

function initSwipers() {

    if (collectionCrossListing.length > 0) {
        let collectionCrossListingSwiper = new Swiper('.cross-listing__carousel', {
            direction: 'horizontal',
            slidesPerView: 5,
            breakpoints: {
                320: {
                    slidesPerView: 1.25,
                },
                768: {
                    slidesPerView: 3.25,
                },
                1024: {
                    slidesPerView: 5,
                }
            }
        });
    }
    

	collectionItemThumbnailGallerySwiper = new Swiper(collectionItemThumbnailGallery, {
		// Optional parameters. Disable loop so the variant selection doesn't call the wrong slide
		direction: window.innerWidth < 768 ? 'horizontal' : 'vertical',
		loop: false,
		spaceBetween: 0,
		slidesPerView: 'auto',
		/* freeMode: true, */
		watchSlidesProgress: true,
		breakpoints: {
			768: {
				direction: 'vertical',
			}
		}
	});

	collectionItemGallerySwiper = new Swiper(collectionItemGallery, {
		// Optional parameters. Disable loop so the variant selection doesn't call the wrong slide
		direction: 'horizontal',
		loop: false,
		// Navigation arrows
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		thumbs: {
			swiper: collectionItemThumbnailGallerySwiper,
		},


	});

}
function initGallary() {
	document.addEventListener("DOMContentLoaded", function () {
		const galleryItems = document.querySelectorAll('.gallery-item img');
		const squareImages = [];
		const landscapeImages = [];
		const portraitImages = [];
		
		galleryItems.forEach(img => {
		const applyClass = () => {
		const width = img.naturalWidth;
		const height = img.naturalHeight;
		const ratio = width / height;
		
		if (ratio > 1) { // Landscape
		img.parentElement.classList.add('landscape');
		landscapeImages.push(img.parentElement);
		} else if (ratio === 1) { // Square
		img.parentElement.classList.add('square');
		squareImages.push(img.parentElement);
		} else { // Portrait
		img.parentElement.classList.add('portrait');
		portraitImages.push(img.parentElement);
		}
		};
		
		if (img.complete) {
		applyClass();
		} else {
		img.onload = applyClass;
		}
		});
		
		});
	};
export { collectionCrossListing, collectionCrossListingSwiper, collectionItemThumbnailGalleries, collectionItemThumbnailGallery, collectionItemThumbnailGallerySwiper, collectionItemGalleries, collectionItemGallery, collectionItemGallerySwiper, initGallary,initSwipers };