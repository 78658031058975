// All Functions for Latest 

function initLatest() {
  checkPriceZero();
  checkSummaryPage();
}

// Check if the price is 0 and replace it with POA
function checkPriceZero() {
    const prices = document.querySelectorAll('.collection-item-variant-prices');
    prices.forEach((price) => {
        if (price.textContent.includes('$0')) {

            price.textContent = 'POA';
        }
    });

    //After SPRIG request completes
    document.body.addEventListener('htmx:afterSwap', function(event) {
        checkPriceZero();
    });
}

function checkSummaryPage() {
    const summaryPage = document.querySelector('.selection-summary');
    const sidebar = document.querySelector('#side-quote-panel');
    if (summaryPage && sidebar) {
        console.log('Summary page detected');
        sidebar.classList.add('hidden');
    }
}

export { initLatest };