// footer.js
function initFooter() {
	document.addEventListener("DOMContentLoaded", () => {
		const siteHeader = document.querySelector("#site-header");
		const footer = document.querySelector(".contact-footer");
		
		if (footer) {
			const parallaxVideo = document.querySelector(".hero-first");
			const footerZIndex = "z-20";
			const bufferHeight = 0;
			const desktopNavHeight = 150; // Height of the navigation on desktop
			const mobileNavHeight = 40; // Height of the navigation on tablet and below

			function getNavHeight() {
				return window.innerWidth <= 768 ? mobileNavHeight : desktopNavHeight;
			}

			function handleScroll() {
				const navHeight = getNavHeight();
				if (
					window.scrollY + window.innerHeight >=
					document.documentElement.scrollHeight - navHeight
				) {
					footer.classList.add(footerZIndex);
					siteHeader.classList.add("footer--visible");
					parallaxVideo?.classList.add("hidden");
					console.log("Scrolled to the bottom of the page");
				} else if (
					window.scrollY + window.innerHeight <=
					document.documentElement.scrollHeight - bufferHeight - navHeight
				) {
					footer.classList.remove(footerZIndex);
					siteHeader.classList.remove("footer--visible");
					parallaxVideo?.classList.remove("hidden");
				}
			}

			window.addEventListener("scroll", handleScroll);
			window.addEventListener("resize", handleScroll);

			// Add an event listener to capture clicks on links with href="#footer"
			document.querySelectorAll('a[href="#footer"]').forEach((link) => {
				link.addEventListener("click", (event) => {
					event.preventDefault(); // Prevent the default anchor behavior
					const navHeight = getNavHeight();
					window.scrollTo({
						top: document.documentElement.scrollHeight - navHeight,
						behavior: "smooth",
					});
				});
			});
		}
	});
}
function backToTop() {
/* 	document.addEventListener("DOMContentLoaded", function () {
		const backToTopButton = document.getElementById("backToTop");

		if (backToTopButton) {
			window.addEventListener("scroll", function () {
				if (window.scrollY > window.innerHeight) {
					backToTopButton.classList.remove("hidden");
					backToTopButton.classList.add("opacity-100");
				} else {
					backToTopButton.classList.add("hidden");
					backToTopButton.classList.remove("opacity-100");
				}
			});

			backToTopButton.addEventListener("click", function () {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			});
		} else {
			console.error("Back to Top button not found.");
		}
	}); */
}
export { initFooter, backToTop };
