/* import "@/css/global.css"; */
import '@/css/app.pcss';
import { initFooter, backToTop } from './modules/footer.js';
import { initFlowbite } from 'flowbite';

import {
  toggleMenu,
  pageBody,
  menu,
  menuItems,
  initToggleMenu,
} from './modules/navigation.js';

import {
    collectionCrossListing, 
    collectionCrossListingSwiper,
  collectionItemThumbnailGalleries,
  collectionItemThumbnailGallery,
  collectionItemThumbnailGallerySwiper,
  collectionItemGalleries,
  collectionItemGallery,
  collectionItemGallerySwiper,
  initGallary,
  initSwipers,
} from './modules/galleries.js';

import {
  quotePanelToggleBtns,
  quoteListPanel,
  initQuotePanelToggleBtns,
} from './modules/quote-panel-toggles.js';

import {
  initVariantSelectField,
  initVariantOptionSelectField,
  initVariantOptionToggles,
  updateVariantPrice,
  updateWishlistVariantOptionField,
  updateListItemImageField,
  updateVariantOptionTitle,
  goToGallerySlide,
  testVariantOptionInList,
} from './modules/variant-and-variant-option-switchers.js';

import {
  dynamicQuoteHolder,
  quoteParser,
  quoteDoc,
  parsedQuoteData,
  quoteCount,
  reloadQuote,
  wishListForm,
  formSuccessMessage,
  wishListFormData,
  itemVariantOptionsSection,
  wishlistVariantOptionFields,
  checkedRadio,
  wishListFormItemID,
  wishListsubmitBtns,
  initWishListSubmit,
  quoteForm,
  quoteData,
  updateQuoteQuantities,
  itemQuantityFields,
  removeFieldId,
  removeField,
  itemContainerId,
  itemContainer,
  initItemQuantityFields,
  quoteCartCounters,
  updateQuoteCartCounters,
} from './modules/wishlist-functions.js';
import { initTitleResize } from './modules/heading.js';

import * as categoryPage from './modules/sv/category.js';

// Collection Page
import * as collectionPage from './modules/collection.js';

import { initLatest } from './modules/latest.js';

// For the on Doc ready: Define a convenience method and use it
let ready = (callback) => {
  if (document.readyState != 'loading') callback();
  else document.addEventListener('DOMContentLoaded', callback);
};

ready(() => {
  /* Do things after DOM has fully loaded */
  console.log('Doc is ready!!!');
  initFlowbite();
  initFooter();
  backToTop();
  /* Navigation Module */
  initToggleMenu();

  /* Swiper Sliders Module */
  initSwipers();
  initGallary();
  /* Quote Panel Toggles Module */
  initQuotePanelToggleBtns(quotePanelToggleBtns);

  /* Variant & Variant Option Switchers Module */
  initVariantSelectField();
  initVariantOptionSelectField();
  initVariantOptionToggles();

  /* Wishlist Functions Module */
  initWishListSubmit(wishListsubmitBtns);
  initItemQuantityFields();
  initTitleResize();
  initLatest();

}); // END DOC READY
