function initTitleResize() {
    function updateFontSize() {
        const header = document.querySelector('[data-shrink-header]');
        const stickyHeader = document.querySelector('[data-sticky-header]');
        
        if (!header || !stickyHeader) {
            return;
        }

        const stickyHeaderHeight = stickyHeader.offsetHeight;
        const initialFontSize = parseFloat(header.dataset.initialFontSize);
        const minFontSize = parseFloat(header.dataset.minFontSize);
        const maxScroll = parseFloat(header.dataset.maxScroll) || (stickyHeaderHeight - 70);

        const scrollY = window.scrollY;
        const newFontSize = Math.max(minFontSize, initialFontSize - (scrollY / maxScroll) * (initialFontSize - minFontSize));
        header.style.fontSize = newFontSize + 'rem';
    }

    // Ensure the page starts at the top when loaded or reloaded
    /* window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }; */

    // Call updateFontSize immediately to set the correct initial size
    updateFontSize();

    // Add event listeners after DOM is loaded
    document.addEventListener('DOMContentLoaded', function () {
        // Ensure we're at the top of the page
        window.scrollTo(0, 0);
        
        document.addEventListener('scroll', updateFontSize);
        // Also update on resize in case the layout changes
        window.addEventListener('resize', updateFontSize);
    });
}
export { initTitleResize };